import Vue from 'vue';
import ElementUI from 'element-ui';
import localeEs from 'element-ui/lib/locale/lang/es';
import { formatDistanceToNow, parse, format } from 'date-fns';
import vueDebounce from 'vue-debounce';
import { es } from 'date-fns/locale';
import VueMce from 'vue-mce';
import './styles/styles.scss';
import './registerServiceWorker';
import { showErrors } from '@/plugins/utils';
import router from './router';
import store from './store';
import auth from './plugins/auth';
import App from './App.vue';
import userTypes from './plugins/userTypes';
import { warningTitle } from './plugins/messages';
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

Vue.use(VueMce);

Vue.use(ElementUI, {
  locale: localeEs,
});

Vue.use(vueDebounce);
Vue.config.productionTip = false;

require('./plugins/validator');
require('./plugins/axios');

Vue.prototype.$auth = auth;

import money from 'v-money';

// register directive v-money and component <money>
Vue.use(money, { precision: 4 });

Vue.mixin({
  filters: {
    phones(values) {
      if (Array.isArray(values)) {
        const numbers = values.map((v) => v.number);
        return numbers.join(' - ');
      }
      return values;
    },
    emails(values) {
      if (Array.isArray(values)) {
        const emails = values.map((v) => v.email);
        return emails.join(' - ');
      }
      return values;
    },
    appraisalnumber(value) {
      return String(value).padStart(6, '0');
    },
    toPrice(value, decimals = 2) {
      return Number(value).toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
    },
    typeUserFilter(value) {
      const type = userTypes.find((ut) => ut.value === value);
      return type.label;
    },
    toDateDistanceNowHuman(value) {
      const date = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());
      return formatDistanceToNow(date, { locale: es });
    },
    toShortDate(value) {
      if (!value) {
        return '';
      }
      const date = parse(value, 'yyyy-MM-dd', new Date());
      return format(date, 'dd-MM-yyyy');
    },
    toLongDate(value) {
      if (!value) {
        return '';
      }
      const date = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());
      return format(date, 'dd-MM-yyyy HH:mm:ss');
    },
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters.currentBreakPoint;
    },
  },
  methods: {
    onConnectToSocket() {
      const echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_SOCKET_KEY,
        wsHost: process.env.VUE_APP_SOCKET_URL,
        authEndpoint: process.env.VUE_APP_SOCKET_AUTH_URL,
        wsPort: process.env.VUE_APP_SOCKET_PORT,
        // wssPort: 443,
        disableStats: true,
        forceTLS: process.env.VUE_APP_SOCKET_HTTPS === '1',
        encripted: process.env.VUE_APP_SOCKET_ENCRIPTED === '1',
        cluster: 'mt1',
        enabledTransports: ['ws', 'wss'],
        bearerToken: this.$auth.getOnlyToken(),
        wsHost: window.location.hostname,
        wssPort: 6021,
      });

      Vue.prototype.$echo = echo;
    },
    onConnectToGlobalChannel() {
      setTimeout(() => {
        const channelHome = this.$echo.private('global');
        channelHome
          .subscribed(() => {
            console.log('suscrito al canal global de usuarios');
          })
          .listen('.global', (params) => {
            this.showNotification(params.message_type, params.message, params.user || null);
          });
      }, 1000);
    },
    onConnectToRoleChannel() {
      setTimeout(() => {
        const user = this.$auth.user();
        if (user && user.role) {
          const roleChannel = this.$echo.private(`${user.role.slug}.${user.role.id}`);

          roleChannel
            .subscribed(() => {
              console.log(`suscrito al canal de ${user.role.description}`);
            })
            .listen('.notifications', (params) => {
              const sender = params.user || null;
              if (sender.id !== user.id) {
                this.showNotification(params.message_type, params.message, sender);
              }
            });
        }
      }, 1000);
    },
    onConnectToPersonalChannel() {
      setTimeout(() => {
        const user = this.$auth.user();
        if (user) {
          const personalChannel = this.$echo.private(`users.${user.id}`);

          personalChannel
            .subscribed(() => {
              console.log(`suscrito al canal de personal de ${user.nick}`);
            })
            .listen('.actions', (params) => {
              if (params.type === 'alert') {
                this.showAlert(params.data);
                return;
              }
              this.$store.dispatch(`ON_ADD_${params.type.toUpperCase()}`, params.data);
            });
        }
      }, 1000);
    },
    showNotification(type, message, user = null) {
      let title = 'Atención';
      if (type === 'warning') {
        title = 'Advertencia';
      }
      if (type === 'danger') {
        title = 'Cuidado';
      }

      const params = {
        title,
        type,
        dangerouslyUseHTMLString: false,
      };
      if (user) {
        params.message = `<span>${message}</span></br><span>Por: </span><strong>${user.nick}</strong>`;
        params.dangerouslyUseHTMLString = true;
      } else {
        params.message = message;
      }
      this.$notify(params);
    },
    showErrors,
    onShowDeleteAlert(message) {
      return this.$confirm(message, 'Atención', {
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        customClass: 'modal-delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonClass: 'el-button--text mr-3',
      });
    },
    onShowWarningAlert(message) {
      return this.$confirm(message, 'Cuidado', {
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        customClass: 'modal-warning',
        confirmButtonClass: 'el-button--warning',
        cancelButtonClass: 'el-button--text mr-3',
      });
    },
    onShowSuccessAlert(message = 'Operación realizada con éxito', title = 'Atención') {
      this.$notify({
        title,
        message,
        type: 'success',
      });
    },

    /**
     * Use this method to show a alert
     * We use this method to show an alert when the socket send a message
     * if you want to execute a specific method, you must use the getter isExecutingMethod inside a watcher
     * @param {string} message
     * @param {string} title
     * @param {string} typeAlert
     */
    showAlert({
      title = 'Proceso Exitoso',
      message = 'Se completo el proceso',
      typeAlert = 'success',
    }) {
      this.$store.commit('ON_EXECUTE_METHOD');
      this.$alert(message, title, {
        confirmButtonText: 'OK',
        type: typeAlert,
        center: true,
        showClose: false,
        dangerouslyUseHTMLString: true,
        callback: () => {
          this.$store.commit('ON_EXECUTE_METHOD');
        },
      });
    },
    onVerifyPermissions(code) {
      return this.user.actions.includes(code);
    },
    setFieldsLocalStorage(values, key) {
      localStorage.setItem(key, JSON.stringify(values));
      this.fieldsShow = values;
    },
    showFieldsLocalStorage(key, fields) {
      this.fieldsShow = JSON.parse(localStorage.getItem(key)) || fields;
    },
    setPrefixMoney(currency_id) {
      this.moneyFormat.prefix = currency_id == 'PEN' ? 'S/ ' : '$ ';
    },
    /**
     *
     * @param {string} date Fecha para consultar el tipo de cambio
     * @param {string} formOrProp Nombre de la propiedad que se va a modificar con la tasa de cambio devuelta por la api
     * @param {string} prop Nombre de la propiedad que se va a modificar con la tasa de cambio devuelta por la api
     */
    onGetExchangeRate(date, formOrProp, prop = null) {
      const params = {
        date,
      };
      this.$axios
        .get('search/exchange-rate', { params })
        .then((response) => {
          const { data } = response.data;
          if (prop) {
            this[formOrProp][prop] = data.exchange_rate;
          } else {
            this[formOrProp] = data.data.exchange_rate;
          }
        })
        .catch(() => {
          if (prop) {
            this[formOrProp][prop] = '';
          } else {
            this[formOrProp] = '';
          }
        });
    },
    onShowErrorsInNotifications(error) {
      const { status } = error.response;
      const { data } = error.response;
      if (status === 422) {
        Object.entries(data.errors).forEach(([value]) => {
          value.map((p) => {
            this.$notify({
              title: warningTitle,
              message: p,
              type: 'warning',
            });
          });
        });
      }
    },

    async onGetMyLocalByModule(module_name) {
      try {
        const { data } = await this.$axios.get(`users/get-my-local-by-module`, {
          params: { module_name },
        });
        return data.data;
      } catch (error) {
        this.onShowErrorsInNotifications(error);
      }
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
